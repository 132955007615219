body {
  overflow: initial !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gm-style-iw button {
  outline: 0;
  display: none !important;
}

.fc-v-event {
  background-color: initial;
  box-shadow: none !important;
  border: none !important;
}

.fc-popover-body {
  width: 300px !important;
  max-height: 500px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.fc-popover-body::-webkit-scrollbar {
  display: none;
}

.fc-popover-title {
  color: #1a1a1a;
}
